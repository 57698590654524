export type PayinType = {
  id: number;
  reference: string;
  status: 'success' | 'pending' | 'failed' | 'requires_auth' | 'processing' | 'flagged' | 'cancelled' | 'pre_authorized';
  amount: string;
  fee: string;
  vat: string;
  currency: 'NGN';
  amount_charged: string;
  amount_paid: string;
  payment_source_type: 'card' | 'bank_transfer' | 'payment_reversal' | 'mobile_money' | 'reserved_bank_account';
  channel: 'api' | 'modal' | 'web';
  narration: string | null;
  payment_reversals_type: number;
  meta: {
    stan: string;
    receipt: string;
    error_type?: 'CHARGE_PREVENTED' | 'CARD_RECORD_NOT_FOUND';
    card_details: {
      card_type: 'mastercard' | 'visa';
      first_six: string;
      last_four: string;
      masked_pan: string;
      expiry_year: string;
      expiry_month: string;
    };
    gateway_code?: string;
    charge_operation: 'capture';
    authorization_code: string;
    total_captured_amount: number;
    total_refunded_amount: number;
    total_authorized_amount: number;
    tokenization?: {
      can_tokenize: boolean;
    };
  };
  reason_for_failure: string;
  processor: string;
  processor_reference: string;
  transaction_reference: string;
  payment_reference: string;
  mobile_number: string | null;
  transaction_date: string;
  completed_at: string;
  amount_collected: string;
  source_amount: string;
  payment: {
    reference: string;
    account: {
      id: number;
      name: string;
    };
  };
};

export const payInsTabs = {
  transactions: 'transactions',
  metrics: 'metrics'
} as const;

export type TPayInsTabsKeys = keyof typeof payInsTabs;