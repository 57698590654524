import React from 'react';
import { useQuery } from 'react-query';

import EmptyStateComponent from '+containers/Dashboard/Shared/EmptyState';
import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import { Storage } from '+services/storage-services';
import CustomisedIframe from '+shared/CustomisedIframe';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import { CurrencyType, IPayInMetabaseData, metaBaseModeType } from '+types';
import { isAllowed, storageDataKey } from '+utils';

const api = new APIRequest();
function PayInMetricComponent() {
  const { feedbackInit } = useFeedbackHandler();
  const userAccess = useSetUserAccess();
  const searchQuery = useSearchQuery();
  const activeCurrencyTab = searchQuery.value.currency || 'NGN';
  const availableCurrencies = (Storage.getItem(storageDataKey.AVAILABLE_CURRENCIES) ?? []) as CurrencyType[];
  const { data, refetch, isFetching } = useQuery<IPayInMetabaseData>(
    ['PAY_INS_METRICS'],
    () => api.getMetaBaseData('payins' as metaBaseModeType, activeCurrencyTab as CurrencyType),
    {
      refetchOnMount: 'always',
      keepPreviousData: true,
      onError: () => {
        feedbackInit({
          message: `'There has been an error fetching payin metabase data `,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      },
      enabled: isAllowed(userAccess, ['metabase.view']) as boolean
    }
  );
  const filteredCurrency = availableCurrencies.filter(currency => ['NGN'].includes(currency));
  return (
    <>
      <div className="os-tabs-controls os-tabs-complex" style={{ marginBottom: '50px' }}>
        <ul className="nav nav-tabs">
          {filteredCurrency?.map((currency: CurrencyType) => (
            <li className="nav-item" key={currency}>
              <button
                type="button"
                className={`nav-link ${activeCurrencyTab === currency ? 'active' : ''}`}
                onClick={() => {
                  searchQuery.setQuery({ tab: currency });
                }}
              >
                {currency}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {isFetching && <LoadingPlaceholder type="text" content={2} rows={8} />}
      {data?.urls && data.urls.length > 0
        ? data.urls.map((url: string) => (
            <div key={url}>
              <CustomisedIframe src={url} title={`payins-metabase-${url}`} sandbox="allow-scripts allow-same-origin" />
            </div>
          ))
        : !isFetching && <EmptyStateComponent message="There are no metrics data yet" heading={'No records yet'} />}
    </>
  );
}
export default PayInMetricComponent;
