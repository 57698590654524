/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import Icon from '+containers/Dashboard/Shared/Icons';
import { useFeedbackHandler, useLargeExportDownloader, useSearchQuery, useSetUserAccess, useTransactionStatus } from '+hooks';
import APIRequest from '+services/api-services';
import { Storage } from '+services/storage-services';
import LargeExportModal from '+shared/LargeExportModal';
import Table from '+shared/Table';
import Tooltip from '+shared/Tooltip';
import { CurrencyType, FileFormatType, payInsTabs, PayinType } from '+types';
import {
  APIDownload,
  capitalize,
  capitalizeRemovedash,
  cardStatus,
  filteredOutObjectProperty,
  formatAllTime,
  getDate,
  getPresentDate,
  getTime,
  isAllowed,
  queriesParams,
  storageDataKey,
  switchStatus
} from '+utils';
import useStore from '+zustandStore';

import TransactionStatusModal from '../../Shared/TransactionStatus';
import { getHighlightedAmount } from '../../TransactionDetails/data';

import InfoIcon from '+assets/img/dashboard/information-button.svg';

import '../index.scss';

const api = new APIRequest(process.env.REACT_APP_PUBLIC_MERCHANT_MIDDLEWARE_API_BASE);

function PayInTransactions() {
  const { feedbackInit } = useFeedbackHandler();
  const [showLargeExportModal, setLargeExportModal] = useState(false);
  const searchQuery = useSearchQuery();
  const activeCurrencyTab = searchQuery.value.currency || 'NGN';
  const tab = searchQuery.value.tab || payInsTabs.transactions;
  const page = searchQuery.value.page || '1';
  const limit = searchQuery.value.limit || '10';
  const dateFrom = searchQuery.value.dateFrom || getPresentDate().dateFrom;
  const dateTo = searchQuery.value.dateTo || getPresentDate().dateTo;
  const status = searchQuery.value.status || [];
  const sortingParams = {
    dateFrom: formatAllTime(dateFrom),
    dateTo: formatAllTime(dateTo),
    status: typeof status === 'string' ? [status] : status,
    ...filteredOutObjectProperty(searchQuery.value, [
      queriesParams.tab,
      queriesParams.limit,
      queriesParams.sorterType,
      queriesParams.dateFrom,
      queriesParams.dateTo,
      queriesParams.status,
      queriesParams.page,
      queriesParams.totalItems
    ])
  };
  const allowed = [1, 2, 3, 4, 5, 6, 7, 8];
  const refundSwitch = {
    1: 'a refund',
    2: 'a chargeback',
    3: 'a refund and a chargeback',
    4: 'a reversal',
    5: 'a refund and a reversal',
    6: 'a chargeback and a reversal',
    7: 'a refund, a chargeback and a reversal',
    8: 'an underpayment'
  };

  const availableCurrencies = (Storage.getItem(storageDataKey.AVAILABLE_CURRENCIES) ?? []) as CurrencyType[];
  const { profile } = useStore(state => state);
  const { getDownload } = useLargeExportDownloader('Payins');
  useEffect(() => {
    getDownload();
  }, []);
  const userAccess = useSetUserAccess();
  const {
    data: resolvedData,
    isFetching,
    refetch
  } = useQuery(
    [
      'PAY_IN_TRANSACTIONS',
      filteredOutObjectProperty(searchQuery.value, [queriesParams.totalItems, queriesParams.page]),
      activeCurrencyTab
    ],
    () => api.getTransactions('payins', limit, sortingParams, activeCurrencyTab),
    {
      keepPreviousData: true,
      onError: () => {
        let message = 'There has been an error getting your pay-ins.';
        if (searchQuery.value?.sorterType)
          message = `There has been an error in finding results for your ${searchQuery.value?.sorterType}. `;
        feedbackInit({
          message,
          type: 'danger',
          action: {
            action: () => refetch(),
            name: 'Try again'
          }
        });
      },
      enabled: tab === payInsTabs.transactions
    }
  );

  const data = resolvedData?.data || [];
  const paging = resolvedData?.paging;

  const exportFile = async (format: FileFormatType, close: () => void, fieldsToExport: string | string[]) => {
    try {
      const res = await api.exportTransactions('payins', sortingParams, format, activeCurrencyTab, fieldsToExport);
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = format === 'csv' ? 'csv' : 'xlsx';
        APIDownload(res, `Pay-ins at ${getDate(Date.now())}`, type);
        feedbackInit({
          title: 'Export Successful',
          message: (
            <>
              {' '}
              - Successfully exported <strong>payins transactions.</strong>
            </>
          ),
          type: 'success'
        });
      }
      close();
    } catch (error) {
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error exporting your pay-ins`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  const { state, updateTransactionStatusModalState, handleProcessingLoader } = useTransactionStatus();

  useEffect(() => {
    if (state.clickedTransactionIds?.length) {
      refetch();
    }
  }, [state.clickedTransactionIds]);

  const payins = {
    className: ' --history-table',
    rowURL: '/dashboard/pay-ins',
    rowKey: 'reference',
    emptyStateHeading: 'No records yet',
    emptyStateMessage: 'There are no pay-ins yet.',
    annotations: 'transaction(s)',
    fields: (iter: PayinType) => ({
      data: {
        status: (
          <>
            <span className={`status-pill smaller ${switchStatus(iter.status === 'requires_auth' ? 'pending' : iter.status)}`} />
            <span>{cardStatus[iter.status as keyof typeof cardStatus] || capitalizeRemovedash(iter.status || 'N/A')}</span>
            {allowed.includes(iter.payment_reversals_type) && (
              <Tooltip
                type="refunds_and_cashbacks"
                image={InfoIcon}
                message={<p>This transaction has {refundSwitch[iter.payment_reversals_type as keyof typeof refundSwitch]}</p>}
              />
            )}
            {['processing', 'expired'].includes(iter.status) &&
              ['mobile_money', 'bank_account'].includes(iter.payment_source_type) &&
              !state.clickedTransactionIds?.includes(iter.processor_reference) &&
              isAllowed(userAccess, ['payins_status.update']) && (
                <button
                  onClick={e => {
                    e.stopPropagation();
                    updateTransactionStatusModalState(true, iter);
                  }}
                  data-testid="processing-button"
                  type="button"
                  className="status-update-icon"
                  aria-label="Update transaction status"
                >
                  <Icon name="settings" aria-hidden="true" />
                </button>
              )}
            {state.clickedTransactionIds?.includes(iter.processor_reference) && iter.status === 'processing' && (
              <Tooltip
                type="processing"
                message={
                  <p>A status update request has been made on this transaction. Please refresh after a few minutes to see new status.</p>
                }
                centered
              >
                <span className="rotate">
                  <Icon name="loading" />
                </span>
              </Tooltip>
            )}
          </>
        ),
        transaction_id: <span className="trxn-id">{iter.payment?.reference || iter.reference}</span>,
        merchant: <span className="merch-name">{capitalize(iter?.payment?.account?.name || 'Not Available')}</span>,
        type: iter.payment_source_type === 'reserved_bank_account' ? 'Deposit' : 'Pay-in',
        'Date / Time': (
          <>
            <span>{getDate(iter.transaction_date)}</span>
            <span className="annotation">{getTime(iter.transaction_date)}</span>
          </>
        ),
        amount_charged: (
          <>
            <span>
              <strong>
                {' '}
                {getHighlightedAmount({ amountCollected: iter.amount_collected, amount: iter.amount, sourceAmount: iter.source_amount })}
              </strong>
            </span>
            <span className="annotation">{iter.currency}</span>
          </>
        )
      }
    })
  };

  const tableDataKeys = Object.keys(payins.fields({} as PayinType).data);
  return (
    <>
      <LargeExportModal close={() => setLargeExportModal(false)} email={profile.email as string} visible={showLargeExportModal} />

      <section className="os-tabs-w">
        {
          <div className="os-tabs-controls os-tabs-complex mb-4">
            <ul className="nav nav-tabs">
              {availableCurrencies?.map((currency: CurrencyType) => (
                <li className="nav-item" key={currency}>
                  <button
                    type="button"
                    className={`nav-link ${activeCurrencyTab === currency ? 'active' : ''}`}
                    onClick={() => {
                      searchQuery.setQuery({ currency }, true);
                    }}
                  >
                    {currency}
                  </button>
                </li>
              ))}
            </ul>
          </div>
        }
      </section>
      <div className="row">
        <div className="col-sm-12">
          <Table
            className={payins.className || ''}
            loading={isFetching}
            data={data}
            renderFields
            hasPagination
            tableHeadings={tableDataKeys}
            annotation={payins.annotations}
            current={parseInt(page, 10)}
            rowKey={payins.rowKey}
            rowURL={payins.rowURL}
            emptyStateHeading={payins.emptyStateHeading || ''}
            tableWrapperClassName="table-responsive"
            emptyStateMessage={payins.emptyStateMessage || ''}
            filterType="pay-in"
            filterExportAction={exportFile}
            filterActiveCurrency={activeCurrencyTab}
            filterShowExport={isAllowed(userAccess, ['pay_ins.export']) as boolean}
            cursors={paging}
          >
            {payins.fields}
          </Table>
        </div>
      </div>

      {state.openTransactionStatusModal && (
        <TransactionStatusModal
          activeTransaction={state.activeTransaction}
          updateModalState={updateTransactionStatusModalState}
          triggerProcessingLoader={handleProcessingLoader}
          transactionType="payins"
        />
      )}
    </>
  );
}
export default PayInTransactions;
