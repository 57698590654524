/* eslint-disable no-nested-ternary */
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { useSearchQuery, useSetUserAccess } from '+hooks';
import { payInsTabs } from '+types';
import { capitalizeRemovedash, isAllowed } from '+utils';

import CardTransactionDetails from '../CardIssuance/CardTransactions/CardTransactionDetails';
import TransactionDetails from '../TransactionDetails';

import './index.scss';

import PayInMetricComponent from './components/PayInMetric';
import PayInTransactions from './components/PayInTransactions';

function PayInComponent() {
  const searchQuery = useSearchQuery();
  const tab = searchQuery.value.tab || payInsTabs.transactions;
  const userAccess = useSetUserAccess();
  const filteredPayInsTabs = Object.entries(payInsTabs).filter(([tab]) =>
    tab === 'metrics' ? isAllowed(userAccess, ['metabase.view']) : true
  );
  return (
    <>
      <div className="content-i">
        <div className="content-box">
          <section className="os-tabs-w">
            <div className="os-tabs-controls os-tabs-complex mb-4">
              <ul className="nav nav-tabs">
                {filteredPayInsTabs.map(([key, value]) => (
                  <li className="nav-item" key={key}>
                    <button
                      type="button"
                      className={`nav-link ${tab === value ? 'active' : ''}`}
                      onClick={() => {
                        searchQuery.setQuery({ tab: value }, true);
                      }}
                    >
                      {capitalizeRemovedash(value)}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </section>
          {tab === payInsTabs.metrics && <PayInMetricComponent />}
          {tab === payInsTabs.transactions && <PayInTransactions />}
        </div>
      </div>
    </>
  );
}

export default function PayInPage() {
  const userAccess = useSetUserAccess();

  return (
    <div className="checkouts-container">
      <Switch>
        <Route exact path="/dashboard/pay-ins" component={PayInComponent} />
        <Route exact path="/dashboard/pay-ins/:id">
          <TransactionDetails />
        </Route>
        <Route exact path="/dashboard/pay-ins/virtual-card/:id">
          {isAllowed(userAccess, ['card_issuance_transaction_details.view']) ? (
            <div style={{ padding: '4%' }}>
              <CardTransactionDetails />
            </div>
          ) : (
            <Redirect to="/dashboard/access-denied" />
          )}
        </Route>
        <Redirect to="/dashboard/access-denied" />
      </Switch>
    </div>
  );
}
