import React, { useState } from 'react';

import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import { CustomisedIframeProps } from '+types';

import './CustomisedIframe.scss';

const CustomisedIframe: React.FC<CustomisedIframeProps> = ({
  src,
  title,
  width = '100%',
  height = '100%',
  className = '',
  allow = 'fullscreen',
  sandbox = '',
  border = '',
  loading = 'eager',
  referrerpolicy = 'strict-origin-when-cross-origin',
  srcDoc = undefined,
  displayIframeLoader = true
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  return (
    <>
      {!isLoaded && displayIframeLoader && <LoadingPlaceholder type="table" content={3} rows={1} />}
      <div className={`iframe-wrapper ${className}`}>
        <iframe
          src={src}
          title={title}
          style={{ border }}
          allow={allow}
          sandbox={sandbox}
          className="iframe-customise"
          width={width}
          height={height}
          loading={loading}
          referrerPolicy={referrerpolicy}
          srcDoc={srcDoc}
          onLoad={displayIframeLoader ? () => setIsLoaded(true) : undefined}
        />
      </div>
    </>
  );
};

export default CustomisedIframe;
